/* Adapted from http://keithclark.co.uk/articles/practical-css-parallax/scroll-snap/ */
/* slide layout
    --------------------------------------------- */
.slides {
  height: 100%;
}
.slide {
  position: relative;
  overflow: hidden;
}
.slide__content {
  width: 100%;
}
.slide__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

/* Large screen layout
--------------------------------------------- */

@media screen and (min-width: 45em) {

  /* parallax */
  @supports ((perspective: 1px) and (not (-webkit-overflow-scrolling: touch))) {
    html, body {
      overflow: hidden;
      height:100%;
    }
    body {
      transform: translateZ(0px); /* Fix paint issues in Edge && Safari H/W acceleration */
    }
    .slides {
      position: absolute;
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      perspective: 300px;
    }
    .slide {
      overflow: initial;
    }
    .slide__bg {
      transform: translateZ(-150px) scale(1.5);
      transform-origin: 50% 50%;
    }
    .slide__content {
      height: 100%;
      z-index: 20;
    }
    .slide, .slide__content {
      transform-style: flat;
    }
  }
}